<template>
  <div>

    <!-- Take Back Control -->
    <b-container>
      <div id="take-back-control" class="mb-2">
        <b-collapse visible id="collapse-1">
          <b-row class="ml-lg-5 ml-xl-0">
            <b-col
              cols="12"
              sm="12"
              md="3"
              lg="4"
              xl="5"
              offset-lg="0"
              class="text-center text-xl-right pr-0 px-0 mr-4 mr-md-0"
            >
              <img
                src="../../assets/forartists/for-artists/take-back-control.png"
                class="take-back-control-image ml-lg-0 ml-xl-0 ml-sm-0"
              />
            </b-col>
            <b-col
              cols="12"
              sm="12"
              md="8"
              lg="7"
              xl="6"
              class="
                take-back-control-info
                mt-0 mt-md-0 mt-lg-4 mt-xl-5
                ml-md-4
                ml-xl-0
                pl-md-2 pl-xl-0
                px-0
                pt-xl-5
                text-center text-md-left
              "
            >
              <div class="section-margin"></div>
              <p class="section-heading mt-3 mt-sm-3">Take Back Control</p>
              <p class="music-revolution-text section-text mb-0">
                Trackd brings creators and fans together,
                <br class="d-none d-sm-block" />
                allowing <br class="d-sm-none" />
                fans to support your music with <br class="d-none d-sm-block" />
                monthly <br class="d-sm-none" />subscription payments.
              </p>

              <div class="text-center text-md-left">
                <b-collapse id="collapse-tbc" class="mt-4 mt-xl-1 info-card-left">
                  <b-card class="bg-dark pl-0 mx-4 mx-sm-5 mx-md-0">
                    <p class="music-revolution-text section-text mb-4">
                      You get to decide what your fans see and hear and how much
                      they pay for your valuable content. We also provide the
                      tools to upload, lock, personalize and share your content
                      to your fans.
                    </p>
                    <p class="music-revolution-text section-text mb-4">
                      What's more is we offer full stats, support, an A&R team
                      and inclusive marketing of your content within Trackd for
                      Free.
                    </p>
                    <p class="music-revolution-text section-text mb-2">
                      You have control, Captain!
                    </p>
                  </b-card>
                </b-collapse>
              </div>

              <b-button
                variant="link"
                v-b-toggle.collapse-tbc
                class="text-light no-underline find-out-more mt-1 mt-sm-3 px-0"
              >
                Find Out More
                <img
                  src="../../assets/forartists/arrow-right-red.svg"
                  class="ml-1 ml-xl-3"
                />
              </b-button>

              <div>
                <b-button
                  href="#"
                  v-b-modal.signup-modal
                  class="expand-section-btn text-center fw-900 fs-15 mt-1 mb-4 mb-xl-5 px-4 mt-xl-4"
                >
                  Create your Free Profile Now
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-collapse>
      </div>
    </b-container>

    <!-- Just Add Fans -->
    <b-container fluid>
      <b-row>
        <b-col class="px-0" cols="8">
          <div
            class="
              border-white
              mt-5 mt-xl-1
              section-border-left
              d-none d-md-block
            "
          ></div>
        </b-col>
      </b-row>

      <b-container>
        <div id="just-add-fans" class="mb-2 px-0">
          <div
            class="border-white d-block d-md-none mt-5 mb-3 section-border-left"
          ></div>

          <b-collapse visible id="collapse-3" class="px-0">
            <b-row class="px-0">
              <b-col
                cols="12"
                sm="12"
                md="4"
                xl="3"
                class="text-center d-md-none"
              >
                <img
                  src="../../assets/forartists/for-artists/just-add-fans.png"
                  class="just-add-fans-image ml-5 ml-sm-0"
                />
              </b-col>

              <b-col
                cols="12"
                sm="12"
                md="8"
                lg="8"
                xl="8"
                class="pr-0 px-0 text-center text-md-right just-add-fans-info"
              >
                <div class="section-margin"></div>
                <p
                  class="
                    section-heading
                    text-center text-md-right
                    
                    mt-4
                    ml-lg-5
                    pl-lg-5
                  "
                >
                  Just Add Fans
                </p>
                <p
                  class="
                    music-revolution-text
                    section-text
                    text-center text-md-right
                    mb-0 mb-md-2
                  "
                >
                  Connect with your fans like never before. Easily<br />
                  provide music and content for your supporters<br />
                  and build your paying fan base.
                </p>
                <span class="clearfix"></span>

                <div>
                  <b-collapse id="collapse-jaf" class="mt-4 info-card-right">
                    <b-card class="bg-dark pl-md-5 ml-md-5 mx-sm-5 mx-md-0">
                      <p class="music-revolution-text section-text text-center text-md-right mb-4 mx-3 mx-md-0 pl-md-5">Streaming platforms are notorious for super low Artist payments. You need to generate millions of monthly streams, just to earn a minimum wage. That’s just not fair and Trackd can help fix the problem.</p>
                      <p class="music-revolution-text section-text text-center text-md-right mb-4 mx-3 mx-md-0 pl-md-5">Just <span class="fw-900">250</span> fans subscribing at {{symbol}}2.99/month would earn you <span class="fw-900">{{symbol}}635 every month</span>. As a comparison, you would need over <i class="fw-400">145,000 streams played every month</i> from the most popular streaming platform to generate the same revenue.</p>
                      <p class="music-revolution-text section-text text-center text-md-right mb-2 mx-3 mx-md-0 pl-0">Similarly, 5000 fans subscribing at {{symbol}}4.99/month would earn you {{symbol}}21,208 every month.</p>
                    </b-card>
                  </b-collapse>
                </div>

                <b-button
                  variant="link"
                  v-b-toggle.collapse-jaf
                  class="
                    text-light
                    no-underline
                    text-center text-small-right
                    float-small-right
                    find-out-more
                    mt-1 mt-sm-3
                    px-0
                  "
                >
                  Find Out More
                  <img
                    src="../../assets/forartists/arrow-right-red.svg"
                    class="ml-1 ml-xl-3"
                  />
                </b-button>

                <span class="clearfix"></span>

                <div>
                  <b-button
                    href="#"
                    v-b-modal.signup-modal
                    class="expand-section-btn text-center text-small-right float-small-right fw-900 fs-15 mt-1 mt-sm-0 mt-md-1 mt-lg-2 mt-xl-4 px-4"
                  >
                    Create your Free Profile Now
                  </b-button>
                </div>
              </b-col>
              <b-col
                cols="12"
                sm="12"
                md="4"
                xl="3"
                class="d-none d-md-inline-block text-center px-0"
              >
                <img
                  src="../../assets/forartists/for-artists/just-add-fans.png"
                  class="just-add-fans-image ml-5 ml-sm-0"
                />
              </b-col>
            </b-row>
          </b-collapse>
        </div>
      </b-container>
    </b-container>

    <!-- Creativity Without Exclusivity -->
    <b-container fluid>
      <b-row>
        <b-col class="px-0" cols="8" offset="4" offset-sm="0">
          <div
            class="
              px-0
              border-white
              mt-5
              section-border-right              
              d-none d-sm-block
            "
          ></div>
        </b-col>
      </b-row>

      <b-container class="pb-md-5">
        <div id="creativity-without-exclusivity" class="mb-md-5">
          <b-collapse visible id="collapse-1">
            <b-row class="">
              <b-col
                cols="12"
                sm="12"
                md="3"
                lg="4"
                xl="5"
                offset-lg="0"
                offset-xl="0"
                class="text-center text-xl-right mr-5 mr-md-4 mr-lg-0"
              >
                <img
                  src="../../assets/forartists/for-artists/creativity-without-exclusivity.png"
                  class="
                    creativity-without-exclusivity-image
                    ml-lg-0 ml-xl-0 ml-sm-0
                  "
                />
              </b-col>
              <b-col
                cols="12"
                sm="12"
                md="8"
                lg="7"
                xl="6"
                class="
                  pl-sm-1 pl-xl-2
                  ml-md-4 ml-lg-5 ml-xl-4
                  px-xl-0
                  text-center text-md-left
                "
              >
                <div class="section-margin"></div>
                <p class="section-heading mt-4 mb-1">
                  Creativity without exclusivity
                </p>
                <p class="music-revolution-text section-text mb-0">
                  Artists are provided the tools to create exciting<br />
                  Artist and Song social profiles, add links to their<br />
                  social media and provide compelling back stage<br />
                  content to enrich fan experience in addition to<br />
                  their music library.
                </p>

                <div>
                  <b-collapse id="collapse-cwe" class="mt-4 mt-xl-1 info-card-left">
                    <b-card class="bg-dark pr-md-4 pr-xl-0 mx-sm-3 mx-md-0">
                      <p
                        class="
                          music-revolution-text
                          section-text
                          mb-4
                          pr-md-0
                          pr-xl-4
                          mr-md-5
                          mr-xl-2
                          mx-sm-4
                          mx-md-0
                        "
                      >
                        Engage with your fans and build a creative hub that
                        drives your career while generating a fair and
                        sustainable revenue.
                      </p>
                      <p
                        class="
                          music-revolution-text
                          section-text
                          mb-0
                          pr-md-5
                          pr-xl-4
                          mr-md-5
                          mr-xl-2
                          mx-sm-4
                          mx-md-0
                        "
                      >
                        Trackd requires no exclusivity and is free to use. Feel
                        free to keep your music and content on any other
                        platform or site and compare the results.
                      </p>
                    </b-card>
                  </b-collapse>
                </div>

                <b-button
                  variant="link"
                  v-b-toggle.collapse-cwe
                  class="
                    text-left text-light
                    no-underline
                    find-out-more
                    mt-1 mt-sm-3
                    px-0
                  "
                >
                  Find out more
                  <img
                    src="../../assets/forartists/arrow-right-red.svg"
                    class="ml-1 ml-xl-3"
                  />
                </b-button>

                <div class="text-center text-small-left text-md-left">
                  <b-button
                    href="#"
                    v-b-modal.signup-modal
                    class="expand-section-btn fw-900 fs-15 mt-1 mt-xl-4 mb-5 px-4"
                  >
                    Create your Free Profile Now
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-collapse>
        </div>
      </b-container>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "ForArtistsExpandContent",
  props: ['symbol'],

  methods: {
    redirectToSite() {
      //console.log(process.env.VUE_APP_REDIRECT_URL)
      window.location = process.env.VUE_APP_REDIRECT_URL
    }
  },
}
</script>

<style scoped>


.app-store {
  position: absolute;
  top: 20px;
  right: 50px;
}

.home-top {
  justify-content: center;
  margin-top: 40px;
}

.home-top > h5 {
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
}

.home-content {
  position: relative;
  top: 9.5em;
  right: 0%;
  width: 100%;
}

.home-body {
  justify-content: center;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
}

.home-body > h1 {
  font-weight: 200;
  font-size: 60px;
  line-height: 70px;
  text-align: center;
  margin-bottom: 0;
}

.home-body > h3 {
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 0;
}

.home-body > h5 {
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin-bottom: 0;
}

.home-body > p {
  font-weight: 900;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 0;
}

.home-body > span {
  font-weight: 900;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  display: block;
}

.upload-btn {
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  width: 144px;
  height: 37px;
}

.home-footer {
  font-weight: 300 !important;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
  text-decoration-line: none !important;
}

.for-artists-heading {
  font-size: 10px;
  font-weight: 900;
  line-height: 30px;
}

.section-heading {
  font-size: 24px;
  font-weight: 900;
  line-height: 40px;
  margin-bottom: 0px;
}

.section-text {
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
}

.section-link {
  font-size: 14px;
  font-weight: 300;
  line-height: 30px;
  color: white;
}

.section-border-left {
  margin-left: -10%;
  width: 62%;
}

.section-border-right {
  margin-right: -4%;
  margin-left: 25%;
  width: 75%;
}

.take-back-control-image {
  height: 122px;
  transform: translateX(15%);
  
}

.run-the-numbers-image {
  height: 151px;
}

.just-add-fans-image {
  height: 120px;
  transform: translateX(-7%);
}

.creativity-without-exclusivity-image {
  height: 150px;
  transform: translateX(10%);
}

.just-upload {
  margin-left: 2.7em;
  margin-top: 3em;
  width: 152px;
}

.chipin-icons {
  width: 96px;
  height: 30px;
}

.music-revolution {
  font-size: 18px;
  font-weight: 900;
}

.music-revolution-text {
  font-size: 14px;
  line-height: 16px;
  font-weight: 300;
}

.find-out-more {
  font-size: 14px;
  font-weight: 300;
  line-height: 30px;
}

.find-out-more > img {
  width: 10px;
}

.expand-section-btn {
  transform: translate(5%, 0%);
  background: rgb(0, 0, 0);
  border: 1px solid #ffffff;
  border-radius: 10px;
  font-weight: 900;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  width: 207px;
  height: 25px;
}

.border-white {
  background-color: #fff !important;
  color: #fff !important;
  height: 5px;
  opacity: 1 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-200 {
  font-weight: 200 !important;
}

.fw-100 {
  font-weight: 100 !important;
}

.rounded-10 {
  border-radius: 10px;
}

.rounded-12 {
  border-radius: 12px;
}

.fs-40 {
  font-size: 40px;
}

.fs-24 {
  font-size: 24px;
}

.fs-22 {
  font-size: 22px;
}

.fs-20 {
  font-size: 20px;
}

.fs-18 {
  font-size: 18px;
}

.fs-16 {
  font-size: 16px;
}

.fs-14 {
  font-size: 14px;
}

.f-r {
  float: right !important;
}

.no-underline {
  text-decoration: none;
}

.t-ar {
  text-align: right !important;
}

.lh-auto {
  line-height: 40px !important;
}

@media (max-width: 575.98px) {
  .app-store {
    display: none;
  }
  .home-top {
    margin-top: 19px;
  }

  .home-body {
    justify-content: center;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
  }

  .home-body > h1 {
    font-size: 48px;
    line-height: 60px;
    font-weight: 200;
  }
  .home-body > h3 {
    font-size: 16px;
    line-height: 22px;
  }

  .freak-on {
    margin-top: 20%;
  }
}

@media (min-width: 576px) {
  .section-heading {
    font-size: 24px;
    font-weight: 100;
    line-height: 50px;
    margin-bottom: 0px;
  }

  .home-content {
    position: relative;
    top: 10em;
    left: 2.5%;
    width: 100%;
  }

  .home-body {
    justify-content: center;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
  }

  .home-body > h1 {
    font-size: 60px;
    line-height: 70px;
  }

  .home-body > h3 {
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 0;
  }

  .home-body > h5 {
    font-weight: 900;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 0;
  }

  .for-artists-heading {
    font-size: 14px;
    font-weight: 900;
    line-height: 30px;
  }

  .section-text {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
  }

  .section-link {
    font-size: 14px;
    font-weight: 300;
    line-height: 30px;
    color: white;
  }

  .section-border-left {
    margin-left: -25%;
    width: 70%;
  }

  .section-border-right {
    margin-right: 0%;
    margin-left: 75%;
    width: 75%;
  }

  .take-back-control-image {
    height: 185px;
    transform: translateX(15%);
  }

  .section-margin {
    margin-top: 0em;
  }

  .run-the-numbers-image {
    height: 185px;
    transform: translateX(15%);
  }

  .just-add-fans-image {
    height: 185px;
    transform: translateX(5%);
  }

  .creativity-without-exclusivity-image {
    height: 200px;
    transform: translateX(10%);
  }

  .expand-section-btn {
    transform: translate(0%, 0%);
    background: rgb(0, 0, 0);
    border: 1px solid #ffffff;
    border-radius: 10px;
    font-weight: 900;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    width: 208px;
    height: 25px;
  }

  .chipin-icons {
    width: 145px;
    height: 45px;
  }

  .info-card-left {
    margin-left: 65px;
    margin-right: 65px;
  }

  .info-card-right {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media (min-width: 768px) {
  .home-content {
    position: relative;
    top: 10em;
    left: 2.5%;
    width: 100%;
  }

  .home-body > h1 {
    font-weight: 200;
    font-size: 70px;
    line-height: 90px;
  }

  .home-body > h3 {
    font-weight: 900;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 0;
  }

  .home-body > h5 {
    font-weight: 900;
    font-size: 18px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 0;
  }

  .section-heading {
    font-size: 30px;
    font-weight: 900;
    line-height: 60px;
    margin-bottom: 0px;
  }

  .section-text {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
  }

  .section-link {
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
    color: white;
  }

  .section-border-left {
    margin-left: -10%;
    width: 112%;
  }

  .section-border-right {
    margin-right: -8%;
    margin-left: 50%;
    width: 100%;
  }

  .section-margin {
    margin-top: 0em;
  }

  .take-back-control-image {
    height: 180px;
    transform: translate(12%, -8%);
  }

  .run-the-numbers-image {
    height: 225px;
    transform: translateX(0%);
  }

  .just-add-fans-image {
    height: 180px;
    transform: translate(12%, -24%);
  }

  .creativity-without-exclusivity-image {
    height: 210px;
    transform: translate(-5%, -10%);
  }

  .expand-section {
    background-color: rgba(255, 255, 255, 0.1);
    border-top: 5px solid #4a4a4a;
    border-bottom: 5px solid #4a4a4a;
  }

  .expand-section-title {
    font-size: 40px;
    font-weight: 200;
    line-height: 60px;
  }

  .expand-section-subtitle {
    font-size: 24px;
    font-weight: 500;
    line-height: 20px;
  }

  .expand-section-body {
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
  }

  .expand-section-btn {
    transform: translateY(40%);
    background: rgb(0, 0, 0);
    border: 1px solid #ffffff;
    border-radius: 10px;
    font-weight: 900;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    width: 235px;
    height: 30px;
  }

  .expand-section .image-adjust {
    transform: translateX(15%);
  }

  .just-upload {
    margin-left: 5em;
    margin-top: 5em;
    width: 275px;
    /* transform: translateX(12%); */
  }

  .find-out-more {
    font-size: 18px;
    font-weight: 300;
  }

  .music-revolution-heading {
    font-size: 40px;
  }

  .info-card-left {
    margin-left: 0px;
    margin-right: 0px;
  }

  .info-card-right {
    margin-left: 0px;
    margin-right: 0px;
  }
  
}

@media (min-width: 992px) {
  .home-content {
    position: relative;
    top: 16em;
    left: 2%;
    width: 100%;
  }

  .home-body > h1 {
    font-weight: 200;
    font-size: 80px;
    line-height: 90px;
  }

  .home-body > h3 {
    font-weight: 900;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 0;
  }

  .home-body > h5 {
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 0;
  }

  .section-heading {
    font-size: 40px;
    font-weight: 900;
    line-height: 70px;
    margin-bottom: 0px;
  }

  .section-text {
    font-size: 24px;
    font-weight: 300;
    line-height: 30px;
  }

  .section-link {
    font-size: 18px;
    font-weight: 300;
    line-height: 30px;
    color: white;
  }

  .section-border-left {
    margin-left: -20%;
    width: 112%;
  }

  .section-border-right {
    margin-right: 0%;
    margin-left: 55%;
    width: 95%;
  }

  .take-back-control-image {
    height: 252px;
    transform: translate(4%, -9%);
  }

  .take-back-info {
    transform: translate(-5%, 0%);
  }

  .run-the-numbers-image {
    height: 286px;
    transform: translateX(0%);
  }

  .just-add-fans-image {
    height: 257px;
    transform: translate(4%, -23%);
  }

  .creativity-without-exclusivity-image {
    height: 295px;
    transform: translate(12%, -20%);
  }

  .expand-section {
    background-color: rgba(255, 255, 255, 0.1);
    border-top: 5px solid #4a4a4a;
    border-bottom: 5px solid #4a4a4a;
  }

  .expand-section-title {
    font-size: 40px;
    font-weight: 200;
    line-height: 60px;
  }

  .expand-section-subtitle {
    font-size: 24px;
    font-weight: 500;
    line-height: 20px;
  }

  .expand-section-body {
    font-size: 24px;
    font-weight: 300;
    line-height: 30px;
  }

  .expand-section-btn {
    transform: translateY(40%);
    background: rgb(0, 0, 0);
    border: 1px solid #ffffff;
    border-radius: 10px;
    font-weight: 300;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    width: 250px;
    height: 37px;
  }

  .expand-section .image-adjust {
    transform: translateX(15%);
  }

  .just-upload {
    margin-left: 3em;
    margin-top: 6em;
    width: 347px;
  }

  .find-out-more {
    font-size: 24px;
    font-weight: 300;
  }

  .find-out-more > img {
    width: 20px;
  }

  .info-card-left {
    padding-top: 20px;
    margin-left: 0px;
    margin-right: 30px;
  }

  .info-card-right {
    margin-left: 60px;
    margin-right: 0px;
  }
}

@media (min-width: 1200px) {
  .home-content {
    max-width: 1200px;
    position: relative;
    top: 5em;
    left: 1.2%;
    width: 100%;
  }

  .home-body > h1 {
    font-weight: 200;
    font-size: 80px;
    line-height: 90px;
  }

  .home-body > h3 {
    font-weight: 900;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 0;
  }

  .home-body > h5 {
    font-weight: 900;
    font-size: 18px;
    line-height: 40px;
    text-align: center;
    margin-bottom: 0;
  }

  .find-out-more {
    font-size: 24px;
    font-weight: 200;
    line-height: 30px;
  }
  
  .find-out-more > img {
    width: 20px;
  }

  .section-heading {
    font-size: 40px;
    font-weight: 900;
    line-height: 70px;
    margin-bottom: 0px;
  }

  .take-back-control-heading {
    line-height: 70px;
  }

  .take-back-control-info {
    transform: translate(-2%, -4%);
  }

  .section-text {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
  }

  .section-link {
    font-size: 24px;
    font-weight: 900;
    line-height: 30px;
    color: white;
  }

  .section-border-left {
    margin-left: 0;
    margin-right: 0;
    width: 99.2%;
  }

  .section-border-right {
    width: 88%;
    margin-left: 62%;
  }

  .take-back-control-image {
    height: 252px;
    transform: translate(9px, -2px);
  }

  .take-back-info {
    transform: translate(-4%, 7%);
  }

  .just-add-fans-image {
    height: 257px;
    transform: translate(23%, -23%);
  }

  .just-add-fans-info {
    transform: translate(8%, -3%);
  }

  .expand-section {
    background-color: rgba(255, 255, 255, 0.1);
    border-top: 5px solid #4a4a4a;
    border-bottom: 5px solid #4a4a4a;
  }

  .expand-section-title {
    font-size: 40px;
    font-weight: 200;
    line-height: 60px;
  }

  .expand-section-subtitle {
    font-size: 24px;
    font-weight: 500;
    line-height: 20px;
  }

  .expand-section-body {
    font-size: 24px;
    font-weight: 300;
    line-height: 30px;
  }

  .expand-section-btn {
    transform: translateY(0%);
    background: rgb(0, 0, 0);
    border: 1px solid #ffffff;
    border-radius: 10px;
    font-weight: 300;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    width: 250px;
    height: 37px;
  }

  .expand-section .image-adjust {
    transform: translateX(15%);
  }

  .just-upload {
    margin-left: 3em;
    margin-top: 4em;
    transform: translateX(10%);
  }

  .creativity-without-exclusivity-image {
    height: 295px;
    transform: translate(12%, -25%);
  }

  .arrow-down {
    height: 10px;
  }

  .music-revolution-text {
    font-size: 24px;
    line-height: 30px;
    font-weight: 300;
  }

  .info-card-left {
    padding-top: 20px;
    margin-left: 0px;
    margin-right: 85px;
  }

  .info-card-right {
    margin-left: 60px;
    margin-right: 0px;
  }


}

@media(min-width: 1400px) {


  .border-white {
    height: 5px;
    opacity: 1 !important;
  }

  .section-border-left {
    margin-left: 0;
    margin-right: 0;
    width: 99.2%;
    background-color: black !important;
    background: linear-gradient(270deg, rgba(255, 255, 255, 1) 60%, rgba(0, 0, 0, 0.7) 90%, rgba(0, 0, 0, 1) 95%);
  }

  .section-border-right {
    width: 82%;
    margin-left: 68%;
    background-color: black !important;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 60%, rgba(0, 0, 0, 0.7) 90%, rgba(0, 0, 0, 1) 95%);
  }

}

@media (min-width: 1600px) {
  .section-border-left {
    margin-left: 0;
    margin-right: 0;
    width: 95%;
  }

  .section-border-right {
    width: 82%;
    margin-left: 68%;
  }
}

@media (min-width: 2000px) {
  .section-border-left {
    margin-left: -150px;
    margin-right: 0;
    width: 99.5%;
  }

  .section-border-right {
    width: 110%;
    margin-left: 68%;
  }
}

</style>