<template>
  <div id="container" style="margin-top: 80px;">

    <BannerTwo v-bind:symbol="symbol" />

    <div class="run-section">
      <RunTheNumbers></RunTheNumbers>
    </div>
    
    <ForArtists v-bind:symbol="symbol"></ForArtists>

  </div>
</template>

<script>

import axios from "axios";
import BannerTwo from '../../components/ForArtists/banner/BannerTwo.vue'
import ForArtists from '../../components/ForArtists/ForArtists.vue'
import RunTheNumbers from '../../components/ForArtists/RunTheNumbers.vue'
import { mapGetters } from 'vuex';

export default {
  name: "Landing",
  components: {
    BannerTwo,
    ForArtists,
    RunTheNumbers,
  },
  data() {
    return {
      symbol: "£",
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    background_image () {
      const random_number = Math.floor(Math.random() * 3) + 1
      return '../assets/img/banner/background-0' + random_number.toString() + '.jpg'
    },
  },
  mounted() {
    axios.get("/onboarding/get-location").then((res) => {
      this.symbol = res.data.symbol;
      // alert(this.symbol)
    });
  }
};
</script>

<style scoped>




@media (min-width: 768px) {

  .run-section {
    margin-top: -15em;
  }

}

@media (min-width: 992px) {

  .run-section {
    margin-top: -21.7em;
  }

}

@media (min-width: 1200px) {

  .run-section {
    margin-top: -12.8em;
  }

}

@media (min-width: 1400px) {

  .run-section {
    margin-top: -12em;
  }

}

@media (min-width: 2000px) {

  .run-section {
    margin-top: -23em;
  }

  #container {
    max-width: 1200px;
    margin: auto;
  }

}

@media (min-width: 2560px) {
  #container {
    max-width: 1200px;
    margin: auto;
  }
}

</style>
