<template>
  <div class="text-light">
    <b-container id="for-artists" class="pb-2 pb-sm-3 pb-md-0 pb-lg-3">
      <div id="for-artists-heading" class="text-center">
        <img
          src="../../assets/forartists/trackd-icon-outline.svg"
          class="mt-3 mt-xl-4 mb-3 d-none d-sm-inline"
        />
        <img
          src="../../assets/forartists/trackd-icon-white-filled.svg"
          class="mt-md-4 d-sm-none"
          width="20px"
        />
        <p class="for-artists-heading text-light text-uppercase d-block">
          Trackd for Artists
        </p>
      </div>
    </b-container>

    <ForArtistsExpandContent slot="Z" chance="1" />
    
    <b-container>
      <div id="music-revolution" class="mb-5">
        <b-row>
          <b-col cols="10" offset="1" class="px-xl-5">
            <div class="border-white d-none d-sm-block"></div>
          </b-col>

          <b-col class="text-center mt-4 pt-3">
            <img src="../../assets/chipin-outline.svg" height="45" class="mr-1 chipin-icons">
            <img src="../../assets/chipin-plus-rounded.svg" height="45" class="ml-3 chipin-icons">
            
            <p class="section-heading music-revolution mt-3 mt-sm-2">Join us in a Music Revolution</p>

            <p class="music-revolution-text">
              Create your account for Free today and join the<br/> 
              music revolution putting ther creator back in<br/>
              control of their fans and valuable content.
            </p>
          </b-col>


          <b-col cols="12" class="align-self-center text-center">
            <b-button
              variant="link"
              :to="{ name: 'Home' }"
              class="d-none text-light no-underline fs-24 fw-300"
            >
              Learn More
              <img src="../../assets/forartists/arrow-right-red.svg" class="ml-3" />
            </b-button>

            <b-button
              v-b-modal.signup-modal 
              class="
                expand-section-btn
                d-inline-block
                mx-auto
                fw-900
                fs-15
                mt-4 mt-xl-1
                px-4
              "
            >
              Create your Free Profile Now
            </b-button>
          </b-col>

          <b-col cols="12" class="text-center">
            <!-- <b-button variant="link" :to="{ name: 'Home' }">
              <img
                src="../../assets/forartists/for-artists/just-upload.png"
                class="just-upload"
              />
            </b-button> -->

            <b-button 
              v-if="isAuthenticated"
              variant="link"
              :to="{name: 'Upload'}"
            >
              <img src="../../assets/forartists/for-artists/just-upload.png" class="just-upload">
            </b-button>
            <b-button 
              v-else
              variant="link"
              :to="{name: 'Home'}"
            >
              <img src="../../assets/forartists/for-artists/just-upload.png" class="just-upload">
            </b-button>

          </b-col>

          <b-col cols="12" class="text-center mt-4 d-xl-none">
            <img
              src="../../assets/forartists/trackd-social-music-platform.svg"
              width="124"
            />
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>


<script>
//import ForArtistsModalContent from "../ForArtists/ForArtistsModalContent.vue"
import ForArtistsExpandContent from "../ForArtists/ForArtistsExpandContent.vue"
import { mapGetters } from "vuex";

export default {
  components: {
    //ForArtistsModalContent,
    ForArtistsExpandContent,
  },
  methods: {
    redirectToSite() {
      //console.log(process.env.VUE_APP_REDIRECT_URL)
      window.location = process.env.VUE_APP_REDIRECT_URL
    }
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  }
};
</script>

<style>

.for-artists-heading {
  font-size: 14px;
  font-weight: 900;
}

.expand-section-btn {
  transform: translate(5%, 0%);
  background: rgb(0, 0, 0);
  border: 1px solid #ffffff;
  border-radius: 10px;
  font-weight: 900;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
}

.just-upload {
  margin-left: 2.7em;
  margin-top: 3em;
  width: 152px;
}

.music-revolution {
  font-size: 18px;
  font-weight: 900;
}

.music-revolution-text {
  font-size: 14px;
  line-height: 16px;
  font-weight: 300;
}

@media (min-width: 576px) {
    
    .expand-section-btn {
        transform: translate(0%, 0%);
        background: rgb(0, 0, 0);
        border: 1px solid #ffffff;
        border-radius: 10px;
        font-weight: 900;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
    }

    .music-revolution {
      font-size: 32px;
      font-weight: 200;
    }
}

@media (min-width: 768px) {
    
    .expand-section-btn {
        transform: translateY(40%);
        background: rgb(0, 0, 0);
        border: 1px solid #ffffff;
        border-radius: 10px;
        font-weight: 900;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
    }

    .expand-section .image-adjust {
        transform: translateX(15%);
    }

    .just-upload {
        margin-left: 5em;
        margin-top: 5em;
        width: 275px;
        /* transform: translateX(12%); */
    }

    .music-revolution {
      font-size: 40px;
      font-weight: 200;
    }

    .music-revolution-heading {
        font-size: 40px;
    }
}

@media (min-width: 992px) {
    .expand-section-btn {
        transform: translateY(40%);
        background: rgb(0, 0, 0);
        border: 1px solid #ffffff;
        border-radius: 10px;
        font-weight: 300;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
    }

    .expand-section .image-adjust {
        transform: translateX(15%);
    }

    .just-upload {
        margin-left: 3em;
        margin-top: 6em;
        width: 347px;
    }

    .music-revolution {
      font-size: 40px;
      font-weight: 200;
    }
}

@media (min-width: 1200px) {
    
    .expand-section-btn {
        transform: translateY(0%);
        background: rgb(0, 0, 0);
        border: 1px solid #ffffff;
        border-radius: 10px;
        font-weight: 300;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
    }

    .expand-section .image-adjust {
        transform: translateX(15%);
    }

    .just-upload {
        margin-left: 3em;
        margin-top: 4em;
        transform: translateX(10%);
    }

    .music-revolution {
      font-size: 40px;
      font-weight: 200;
    }

    .music-revolution-text {
        font-size: 24px;
        line-height: 30px;
        font-weight: 300;
    }
}

</style>